import React from "react";
import styled, { CSSObject } from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSparkles } from "@fortawesome/pro-solid-svg-icons";
import { fontWeightMedium, paddingYXs } from "@Styles";
import { useTheme } from "@emotion/react";
import { fontSizes } from "@Variables/scales";

interface SimpleButtonProps {
  text: string;
  onClick?: () => void;
}

const ButtonContainer: CSSObject[] = [
  {
    display: "inline-flex",
    alignItems: "center",
    padding: "0.75rem 1.5rem",
    border: "none",
    borderRadius: "2rem",
    fontSize: `${fontSizes.sm}rem`,

    "&:focus": {
      outline: "none",
    },
    "&:disabled": {
      cursor: "not-allowed",
    },
  },
  fontWeightMedium,
  paddingYXs,
];

const IconWrapper = styled.span`
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
`;

const Pill: React.FC<SimpleButtonProps> = ({ text, onClick }) => {
  const { colors } = useTheme();

  return (
    <div
      css={[
        ...ButtonContainer,
        { background: colors.primary.main, color: colors.white.main },
      ]}
      onClick={onClick}
    >
      <IconWrapper>
        <FontAwesomeIcon icon={faSparkles} />
      </IconWrapper>
      {text}
    </div>
  );
};

export default Pill;
