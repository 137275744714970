import { useEffect } from "react";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useLocation } from "react-router-dom";

const MatomoPageTracker = () => {
  const { trackPageView } = useMatomo();
  const location = useLocation();

  useEffect(() => {
    console.info("Tracking page view for:", location.pathname);
    trackPageView({
      documentTitle: document.title, // Optionally set the title of the page
    });
  }, [location]); // Trigger tracking on location changes

  return null; // The component does not render anything
};

export default MatomoPageTracker;
