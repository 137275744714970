const matomoOptions = {
  urlBase: "https://innn.matomo.cloud/",
  siteId: 1, // Matomo testing: 202412EndOfYearBanner production
  // siteId: 3, // Matomo testing: 202412EndOfYearBanner staging
  configurations: {
    requireConsent: true,
    requireCookieConsent: true,
    setSecureCookie: true,
  },
};

export default matomoOptions;
