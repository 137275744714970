import { CSSObject } from "@emotion/react";
import {
  faBullseyeArrow,
  faMapMarkerAlt,
  faTags,
  faUserGroup,
} from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";

import { Signable } from "../../types/services/post";
import { fetchActors } from "../helpers/fetchActors";
import { displayBlock, displayFlex } from "../styles/displays";
import { fontSizeMd } from "../styles/fontSizes";
import { fontWeightBold, fontWeightRegular } from "../styles/fontWeights";
import { lineHeightLg } from "../styles/lineHeights";
import {
  marginBottomSm,
  marginBottomXs,
  marginRightXs,
  marginXZero,
  marginYZero,
  marginZero,
  paddingZero,
} from "../styles/spacers";
import Row from "./Row";
import Sticker from "./Sticker";
import { BodySm, Label } from "./typography";
import { breakpointDown } from "@Variables/breakpoints";

const defaultContentStyle: CSSObject[] = [fontWeightRegular, displayFlex];

const prefixStyle: CSSObject[] = [
  fontWeightBold,
  {
    whiteSpace: "nowrap",
  },
  {
    [breakpointDown["sm"]]: marginBottomSm,
  },
];

type IconLabelProps = {
  /** Label type */
  type: "initiators" | "location" | "tag" | "targets";
  /** If a label before names should be displayed. */
  prefix?: boolean;
  /** Object to fetch the label. */
  signable: Signable;
  responsive?: boolean;
};

const IconLabel = ({
  signable,
  type,
  prefix = false,
  responsive = false,
}: IconLabelProps) => {
  const { t } = useTranslation();
  let icon;
  let content = <></>;
  const contentStyle: CSSObject[] = [...defaultContentStyle];

  if (type === "tag") {
    if (signable.tag && signable.tag.title) {
      icon = faTags;
      content = (
        <Sticker
          label={signable.tag.title}
          style="outline"
          variant="secondary"
        />
      );
      contentStyle.push({ alignItems: "center" });
    }
  } else if (type === "location") {
    if (
      signable.type === "initiative" &&
      signable.location &&
      signable.location.name
    ) {
      icon = faMapMarkerAlt;
      content = (
        <Sticker
          label={signable.location.name}
          style="outline"
          variant="secondary"
        />
      );
    }
    contentStyle.push({ alignItems: "center" });
  } else {
    icon = "initiators" == type ? faUserGroup : faBullseyeArrow;

    const prefixLabel =
      "initiators" == type
        ? t("components.iconLabel.prefix.initiators")
        : t("components.iconLabel.prefix.targets");

    const internalDivStyle: CSSObject[] = [...contentStyle];
    if (responsive) {
      internalDivStyle.push({
        [breakpointDown["sm"]]: displayBlock,
      });
    }

    return icon && content ? (
      <Row css={[marginXZero, { width: "100%", alignItems: "center" }]}>
        <div css={[paddingZero, marginBottomXs]}>
          <Label
            css={[
              marginZero,
              contentStyle,
              { alignItems: responsive ? "start" : "center" },
            ]}
            icon={icon}
            variant="secondary"
          >
            <div css={[internalDivStyle]}>
              {prefix && (
                <BodySm
                  css={[prefixStyle, marginYZero, marginRightXs, fontSizeMd]}
                >
                  {prefixLabel}
                </BodySm>
              )}
              <BodySm
                css={[
                  marginZero,
                  lineHeightLg,
                  fontSizeMd,
                  { textAlign: "left" },
                ]}
              >
                {fetchActors(
                  type === "initiators"
                    ? signable.initiators
                    : signable.type === "petition"
                      ? signable.targets
                      : [],
                )}
              </BodySm>
            </div>
          </Label>
        </div>
      </Row>
    ) : null;
  }

  return icon && content ? (
    <Label
      css={contentStyle}
      icon={icon}
      variant="secondary"
    >
      {content}
    </Label>
  ) : null;
};

export default IconLabel;
