import React from "react";

import { CSSObject, useTheme } from "@emotion/react";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import { bgGray1Opacity25, bgWhiteOpacity25 } from "@Styles/backgrounds";
import { colors, fontSizes } from "@Variables";
import themes from "@Variables/themes";
import addUnit from "../helpers/addUnit";

interface CloseButtonProps {
  /** Lightness of the background, used to display the button with the right contrast. */
  bg: "light" | "dark";
  /** Button action. */
  onClick: () => void;
  color?: string;
}

/**
 * Works as a button to close modals, alerts and other elements.
 *
 * The button has an auto left margin, so it's always aligned to the right inside a flexbox element.
 * */
const CloseButton = ({ bg, onClick, color }: CloseButtonProps) => {
  const { tone } = useTheme();
  const { t } = useTranslation();
  const themeColors = themes[tone].colors;
  const sizeInRem = 2.5;

  const activeIconStyle: CSSObject = {
    filter:
      "drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.25)) drop-shadow(0px 1px 3px rgba(118, 118, 118, 0.15))",
  };

  const activeStyle: CSSObject = {
    color: (bg === "light" && themeColors.secondary) || colors.white,
    outline: "none",
    ...((bg === "light" && bgGray1Opacity25) ||
      (bg === "dark" && bgWhiteOpacity25)),
  };

  const hoverStyle = {
    color: (bg === "light" && themeColors.secondary) || colors.white,
  };

  const style: CSSObject = {
    backgroundColor: "transparent",
    border: "none",
    borderRadius: addUnit(sizeInRem / 2, "rem"),
    color: color || themeColors.primary,
    cursor: "pointer",
    fontSize: `${fontSizes["2xl"]}rem`,
    lineHeight: 1,

    width: addUnit(sizeInRem, "rem"),
    height: addUnit(sizeInRem, "rem"),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "auto",

    "&:active, &:focus": activeStyle,
    "&:hover": hoverStyle,

    "&:active svg, &:focus svg, &:hover svg": activeIconStyle,
  };

  const handleCloseButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onClick();
  };

  return (
    <button
      type="button"
      aria-label={t("common.close") as string}
      css={style}
      onClick={handleCloseButton}
    >
      <FontAwesomeIcon
        aria-hidden={false}
        fixedWidth
        icon={faTimes}
      />
    </button>
  );
};

export default CloseButton;
