import React, { useState } from "react";

import { CSSObject, useTheme } from "@emotion/react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import Visible from "@Atoms/Visible";
import {
  bgSecondary,
  marginTopLg,
  paddingBottomMdZero,
  paddingBottomXsLg,
  paddingBottomXsXl,
  paddingTopMdZero,
  paddingTopXsXl,
  paddingXMdZero,
  paddingXXsLg,
  paddingYMdXl,
} from "@Styles";
import { ToneColors } from "@Types/configs";
import Button, { ButtonProps } from "./Button";
import ButtonContainer, { ButtonContainerProps } from "./ButtonContainer";
import CloseButton from "./CloseButton";
import Column, { ColumnNumber } from "./Column";
import { Container } from "./Container";
import Headline from "./organisms/Headline";
import Row from "./Row";

type AlertButtonProps = Omit<ButtonProps, "size" | "theme">;

interface AlertProps {
  /** Array of objects representing the buttons to be displayed in the alert. Each object has all button properties, except `size` and `theme`. */
  buttons?: AlertButtonProps[];
  /** If the close button should be displayed. */
  closeButton?: boolean;
  /** Extra content for the alert. */
  footer?: React.ReactNode;
  /** FontAwesome icon. */
  icon?: IconProp;
  /** Icon color. */
  iconColor?: ToneColors;
  /** Heading text. */
  text?: React.ReactNode;
  /** Heading title. */
  title: React.ReactNode;
  /** Button Container Props */
  buttonProps?: ButtonContainerProps;
}

const headlineWrapperStyle: CSSObject[] = [
  paddingTopXsXl,
  paddingTopMdZero,
  paddingBottomXsLg,
  paddingBottomMdZero,
];

const Alert = ({
  buttons,
  closeButton = false,
  footer,
  icon,
  iconColor,
  text,
  title,
  buttonProps = {},
}: AlertProps) => {
  const { tone } = useTheme();

  const [showAlert, setShowAlert] = useState(true);

  const alertContainerStyle: CSSObject[] = [
    paddingYMdXl,
    paddingBottomXsXl,
    paddingXXsLg,
    paddingXMdZero,
    bgSecondary(tone),
  ];

  let containerColumnSpan: ColumnNumber = 10;

  let buttonsColumnSpan = 0 as ColumnNumber;

  const buttonsLength = buttons?.length ?? 0;
  if (buttonsLength) {
    containerColumnSpan = 5;
    buttonsColumnSpan = 5;
  }

  let closeButtonOffsetSpan = 0 as ColumnNumber;

  const defaultSpan = 12;
  const smallSpan = 12;

  if (closeButton && buttonsColumnSpan > 0) {
    buttonsColumnSpan--;
    closeButtonOffsetSpan = 0 as ColumnNumber;
  }

  if (closeButton && !buttonsColumnSpan) {
    containerColumnSpan--;

    closeButtonOffsetSpan = 0 as ColumnNumber;
  }

  if (!closeButton && !buttons) {
    closeButtonOffsetSpan = 0 as ColumnNumber;
  }

  const footerStyle: CSSObject[] = [marginTopLg];

  return (
    <Visible when={showAlert}>
      <Container
        size="lg"
        css={alertContainerStyle}
      >
        <Row>
          <Column
            span={{
              default: defaultSpan,
              md: containerColumnSpan as ColumnNumber,
              lg: containerColumnSpan as ColumnNumber,
              xs: smallSpan,
            }}
            offset={{ default: 0, md: 1, xs: 0 }}
            order={{ default: 2, md: 1, xs: 2 }}
          >
            <div css={headlineWrapperStyle}>
              <Headline
                icon={{ icon, color: iconColor, size: "3x" }}
                size="lg"
                title={{ title, color: "white", scale: 4 }}
                text={{ title: text, color: "white" }}
              />
            </div>
          </Column>

          {buttons && (
            <Column
              span={{
                default: defaultSpan,
                md: buttonsColumnSpan as ColumnNumber,
                lg: buttonsColumnSpan as ColumnNumber,
                // xs: smallSpan,
              }}
              order={{ default: 3, md: 2, lg: 2 }}
            >
              <ButtonContainer
                align={{
                  default: "justify",
                  sm: "center",
                  md: "left",
                  lg: "left",
                }}
                direction={{
                  default: "vertical",
                  lg: "horizontal",
                  md: "horizontal",
                  sm: "vertical",
                }}
                {...buttonProps}
              >
                {buttons?.map((button, index) => (
                  <Button
                    key={index}
                    {...button}
                    size="md"
                    fullWidth={{
                      default: true,
                      lg: true,
                      md: true,
                      sm: true,
                      xs: true,
                    }}
                  />
                ))}
              </ButtonContainer>
            </Column>
          )}

          {closeButton && (
            <Column
              span={{ default: defaultSpan, md: 1, lg: 1, xs: smallSpan }}
              offset={{
                default: 0,
                md: closeButtonOffsetSpan,
                lg: closeButtonOffsetSpan,
                xs: 0,
              }}
              order={{ default: 1, md: 3 }}
            >
              <CloseButton
                bg="dark"
                onClick={() => setShowAlert(false)}
                color="white"
              />
            </Column>
          )}

          <Visible when={!!footer}>
            <Column
              span={{ default: defaultSpan, md: 8 }}
              offset={{ md: !buttons ? 1 : 0 }}
              order={{ default: 3 }}
            >
              <div css={footerStyle}>{footer}</div>
            </Column>
          </Visible>
        </Row>
      </Container>
    </Visible>
  );
};

export default Alert;
