import { zodResolver } from "@hookform/resolvers/zod";
import { Amount, DonationFormValues } from "@Types/pages";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import schema from "@Validations/home.donation.schema";
import { RadioControlOption } from "@Types/components";
import {
  Body,
  Button,
  Column,
  Container,
  Heading,
  RadioControlGroup,
  Row,
  TextControl,
} from "@Components";
import getDonationUrl from "@Stylize/helpers/getDonationUrl";
import {
  formContainerStyle,
  formInputStyle,
} from "@Appearances/pages/styleHomeDonation";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { faBoxHeart } from "@fortawesome/pro-regular-svg-icons";

import ProgressBar from "@Components/ProgressBar";
import {
  borderOrange,
  elevation2,
  gapMd,
  marginBottomLg,
  marginTopLg,
  marginTopMd,
  marginYMd,
  paddingLeftLg,
  paddingLg,
  paddingMd,
  paddingXLg,
  paddingYMd,
} from "@Styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { CSSObject, useTheme } from "@emotion/react";
import { breakpointDown, breakpointUp } from "@Variables/breakpoints";
import { useCookieOptions } from "@Utils/useCookies";
import { PostsService } from "@Services/posts.service";
import { Banner } from "@Types/services/post";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import Pill from "@Components/Pill";
const firstRowStyle: CSSObject[] = [
  {
    [breakpointUp["md"]]: {
      ...paddingLeftLg,
    },
  },
];

const containerStyle: CSSObject[] = [
  paddingMd,
  {
    [breakpointDown["md"]]: {
      ...paddingMd,
    },
  },
  { ...borderOrange, borderWidth: ".4rem" },
];

type VariationType = "variation" | "original";

const EYOBanner = ({ signableId }: { signableId: string }) => {
  const { t } = useTranslation();
  const { trackEvent, pushInstruction } = useMatomo();

  const variations = {
    original: {
      title: t("common.banners.EOY.original.title"),
      content: t("common.banners.EOY.original.content"),
    },
    variation: {
      title: t("common.banners.EOY.democracy.title"),
      content: t("common.banners.EOY.democracy.content"),
    },
  };

  const postsService = PostsService.getInstance();

  const [post, setPost] = useState<Banner | null>(null);

  const { colors } = useTheme();

  const { bannerClosed, setBannerConsent } = useCookieOptions();

  const [isVisible, setIsVisible] = useState(!bannerClosed);

  const [variation, setVariation] = useState<VariationType>("original"); // Default variation is democracy

  const methods = useForm<DonationFormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      amount: Amount.FIFTY,
      customValue: "",
    },
  });

  const loadBanner = async () => {
    try {
      const { data: post } = await postsService.getBanner("EOY");
      if (post) {
        const currentTime = new Date();
        const endTime = new Date(post.endDate);

        // Check if endDate is in the future
        if (endTime > currentTime) {
          trackEvent({
            category: "202412EndOfYearBanner",
            action: "bannerLoaded",
            name: `${variation}`,
          });
          setPost(post);
        } else {
          setIsVisible(false);
        }
      }
    } catch (error) {
      setIsVisible(false);
      console.error(error);
    }
  };

  const { handleSubmit, setValue } = methods;

  //#region Helpers
  const amounts: RadioControlOption[] = [
    {
      label: `${Amount.TWENTY_FIVE}€`,
      value: Amount.TWENTY_FIVE,
    },
    {
      label: `${Amount.FIFTY}€`,
      value: Amount.FIFTY,
    },
    {
      label: `${Amount.ONE_HUNDRED}€`,
      value: Amount.ONE_HUNDRED,
    },
    {
      label: (
        <TextControl
          type="number"
          name={"customValue"}
          placeholder={`${t("common.donation.otherAmount")}`}
          required
          onFocus={() => setValue("amount", Amount.OTHER)} // Add onFocus event here
        />
      ),
      value: Amount.OTHER,
    },
  ];
  //#endregion

  const onSubmit = (data: DonationFormValues) => {
    console.info("Submitting form and tracking donationStarted event");
    // Trigger donationStarted event in Matomo
    trackEvent({
      category: "202412EndOfYearBanner",
      action: "donationStarted",
      name: `${variation}`,
    });

    const amount =
      data.amount === Amount.OTHER ? data.customValue : data.amount;

    const url = getDonationUrl({
      context: "EOY",
      source: "BannerEOY2024",
      amount: Number(amount),
      signableId: signableId,
      variation: variation,
    });

    // Redirect after a small delay to ensure the event is sent
    setTimeout(() => {
      console.info("Redirecting to:", url);
      window.location.href = url;
    }, 150); // Adjust delay if needed
  };

  const handleBannerClose = () => {
    console.info("Banner close event tracked");
    trackEvent({
      category: "202412EndOfYearBanner",
      action: "bannerClosed",
      name: `${variation}`,
    });
    // Set banner consent to hide it for the user and track bannerClosed event
    setBannerConsent();
    setIsVisible(false);
  };

  useEffect(() => {
    // If the banner is closed, set it to not visible
    if (bannerClosed) {
      setIsVisible(false);
    } else {
      // Otherwise, load the banner data
      loadBanner();
    }
  }, [bannerClosed]);
  useEffect(() => {
    // Matomo testing: 202412EndOfYearBanner
    pushInstruction("AbTesting::create", {
      name: "202412EndOfYearBanner", // you can also use "2" (ID of the experiment) to hide the name
      percentage: 100,
      includedTargets: [
        { attribute: "url", inverted: "0", type: "any", value: "" },
      ],
      excludedTargets: [],
      startDateTime: "2024/11/20 00:00:00 UTC",
      variations: [
        {
          name: "Original",
          activate: function (event: unknown) {
            console.info(event);
            // usually nothing needs to be done here
          },
        },
        {
          name: "variation", // you can also use "2" (ID of the variation) to hide the name
          activate: function (event: unknown) {
            console.info(event);
            setVariation("variation");
          },
        },
      ],
    });
  }, []);

  // Return null if the banner is not visible
  // if (!isVisible) return null;

  return (
    <div
      style={{
        visibility: isVisible ? "visible" : "hidden",
        height: isVisible ? "auto" : "0",
        overflow: isVisible ? "visible" : "hidden",
      }}
      data-nosnippet
    >
      <Container
        size="lg"
        css={containerStyle}
      >
        {/* header breadcrumb and close button */}
        <Row css={firstRowStyle}>
          <Column span={{ default: 10, sm: 7, xs: 9 }}>
            <Pill text="Unterstütze innn.it zum Jahresende" />
          </Column>
          <Column css={{ textAlign: "right" }}>
            <FontAwesomeIcon
              icon={faXmark}
              fontSize={"2rem"}
              color={colors.label[75]}
              css={{ cursor: "pointer" }}
              onClick={handleBannerClose}
            />
          </Column>
        </Row>
        {/* Main content */}
        <Row
          css={[
            marginTopMd,
            {
              [breakpointUp["md"]]: {
                ...paddingXLg,
              },
            },
          ]}
        >
          <Column span={{ default: 12, lg: 6 }}>
            <Heading
              scale={3}
              variant="display"
              color="label"
              css={[
                marginBottomLg,
                {
                  [breakpointUp["xs"]]: {
                    fontSize: "35.60px",
                  },
                  [breakpointUp["md"]]: {
                    fontSize: "61.04px",
                  },
                },
              ]}
            >
              {variations[variation].title}
            </Heading>
            <Body>{variations[variation].content}</Body>
          </Column>
          <Column
            offset={{ default: 0, md: 0, lg: 1 }}
            span={{ default: 12, md: 12, lg: 5 }}
          >
            <div
              css={{
                border: `1px solid ${colors.secondary.main}`,
                borderRadius: "1rem",
                ...paddingMd,
                ...elevation2,
                [breakpointDown["xl"]]: {
                  ...paddingMd,
                  ...paddingYMd,
                },
                [breakpointUp["xl"]]: {
                  ...paddingLg,
                  ...paddingYMd,
                },
              }}
            >
              <div>
                <ProgressBar
                  max={(post && post.goalValue) || 0}
                  value={(post && post.currentValue) || 0}
                />
              </div>
              <FormProvider {...methods}>
                <form
                  noValidate
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div
                    css={[
                      formContainerStyle,
                      marginYMd,
                      {
                        [breakpointDown["lg"]]: {
                          display: "none",
                        },
                      },
                    ]}
                  >
                    <div css={formInputStyle}>
                      <RadioControlGroup
                        containerStyles={{
                          [breakpointUp["lg"]]: {
                            ...gapMd,
                            flexWrap: "nowrap",
                          },
                        }}
                        name="amount"
                        options={amounts}
                        required
                      />
                    </div>
                  </div>

                  <Button
                    type="submit"
                    fullWidth={{ default: true }}
                    label={t("pages.home.donation.buttonLabel")}
                    icon={faBoxHeart}
                    variant="secondary"
                    css={{
                      [breakpointDown["lg"]]: {
                        ...marginTopLg,
                      },
                    }}
                  />
                </form>
              </FormProvider>
            </div>
          </Column>
        </Row>
      </Container>
    </div>
  );
};

export default EYOBanner;
