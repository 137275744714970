import { MatomoProvider, createInstance } from "@jonkoops/matomo-tracker-react";
import { CookiesProvider } from "react-cookie";
import { Outlet, ScrollRestoration } from "react-router-dom";

import {
  CookieBanner,
  CookieModal,
  Footer,
  Loading,
  Main,
  NavBar,
  NewsletterModal,
} from "@Components";
import { AuthProvider } from "@Contexts/AuthContext";
import { ModalsProvider } from "@Contexts/ModalsContext";
import { PageTitleProvider } from "@Contexts/PageTitleContext";
import { SignatureProvider } from "@Contexts/SignatureContext";
import ThemeProvider from "@Contexts/ThemeContext";
import { UserProvider } from "@Contexts/UserContext";
import { GlobalStyles } from "@Stylize/global/GlobalStyles";
import { QueryClientProvider } from "@tanstack/react-query";
import matomoOptions from "@Utils/matomoOptions";
import MatomoPageTracker from "@Utils/MatomoPageTracker";
import { useCookieOptions } from "@Utils/useCookies";
import { Suspense } from "react";
import queryClient from "./configs/react.query";
import AuthenticationModals from "./pages/AuthenticationModals";
import UserProfileModals from "./pages/UserProfileModals";
import { EmailProvider } from "@Contexts/EmailContext";

const App = () => {
  const { cookieConsent } = useCookieOptions();

  return (
    <QueryClientProvider client={queryClient}>
      <MatomoProvider value={createInstance(matomoOptions)}>
        <CookiesProvider>
          <UserProvider>
            <AuthProvider>
              <ThemeProvider>
                <SignatureProvider>
                  <EmailProvider>
                    <PageTitleProvider>
                      <ModalsProvider>
                        <GlobalStyles />
                        <NavBar />
                        <Main>
                          <MatomoPageTracker />
                          <Suspense fallback={<Loading fixed />}>
                            <Outlet />
                          </Suspense>
                        </Main>
                        <Footer />
                        <CookieBanner display={!cookieConsent} />
                        <CookieModal />
                        <NewsletterModal />
                        <AuthenticationModals />
                        <UserProfileModals />
                      </ModalsProvider>
                    </PageTitleProvider>
                  </EmailProvider>
                </SignatureProvider>
              </ThemeProvider>
            </AuthProvider>
          </UserProvider>
        </CookiesProvider>
      </MatomoProvider>
      <ScrollRestoration />
    </QueryClientProvider>
  );
};

export default App;
